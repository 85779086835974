export const REACT = "#67dcf9"
export const JS = "#f0da5b"
export const TYPESCRIPT = "#137bca"
export const APOLLO = "#718093"
export const HOOK_FORM = "#e8588d"
export const GRAPHQL = "#d14aa6"
export const HOOK = "#1289A7"
export const STYLED = "#c05593"
export const EMOTION = "#d26dc1"
export const FIREBASE = "#1b9ce2"
export const PRISMA = "#ff9f1a"
export const POSTGRESQL = "#009432"
export const NODEJS = "#79b461"
export const C = "#38ada9"
export const HTML = "#ee5b2e"
export const CSS = "#2459e9"
